<template>
  <main class="main pa-5">
    <section class="invoice">
      <div class="invoice__content" :style="$vuetify.breakpoint.smAndDown ? 'overflow-x: scroll;' : ''">
        <table id="customers">
          <tr>
            <th :key="item" v-for="item in this.$t('INVOICE.orders.table_header')">{{ item }}</th>
          </tr>
          <tr v-for="order in ordersResponse?.data" :key="order.id">
            <td class="cursor-pointer">
              №{{ order.id }}
            </td>
            <td class="cursor-pointer">
              ${{ order.sum }}
            </td>
            <td class="cursor-pointer">
              {{ invoiceIds(order) }}
            </td>
            <td class="cursor-pointer">
              ${{ getInvoiceSum(order) }}
            </td>
            <td :class="`cursor-pointer ${ORDER_STATUSES_COLORS[order.status]}`">
              {{ $t(`ORDER_HISTORY.statuses.${order.status}`) ?? order.status }}
            </td>
            <td>${{ getPaidSum(order) }}</td>
            <td>{{ getOrderBalance(order) }}</td>
            <td>
              <div class="d-flex align-items-center cursor-pointer"
                @click="$router.push({ name: 'invoice order', params: { id: order.id } })">
                <span class="circle"> </span>
                <span class="show">{{ $t('INVOICE.orders.view') }}</span>
              </div>
            </td>
          </tr>
        </table>

        <nav v-if="ordersResponse?.data" class="p-4" aria-label="Page navigation example">
          <ul class="pagination justify-content-end">
            <li class="page-item disabled">
              <button type="button" class="page-link" @click.prevent="goToPage(currentPage - 1)"
                tabindex="-1">Previous</button>
            </li>
            <li v-for="(page, index) in Array.from(
              {
                length: getTotalPages(ordersResponse?.to, ordersResponse?.per_page),
              },
              (_, index) => index + 1
            )" :key="index" :class="`page-item ${currentPage === page ? 'disabled' : ''}`">
              <button type="button" class="page-link" @click.prevent="goToPage(page)">{{ page }}</button>
            </li>
            <li class="page-item">
              <button type="button" class="page-link" @click.prevent="goToPage(currentPage + 1)">Next</button>
            </li>
          </ul>
        </nav>
      </div>
      <div v-if="ordersResponse?.data?.length" class="row mt-4 justify-content-end">
        <div class="col-4">
          <div class="invoice__content p-5 d-flex align-items-center justify-content-between">
            <p class="fs-6">Ваш баланс:</p>
            <h3 class="fs-3">{{ balance }}</h3>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { ORDER_STATUSES_COLORS } from '@/constants/order_statuses.js';
const { mapState: mapState_Invoice, mapActions: mapActions_Invoice } = createNamespacedHelpers('invoice')
export default {
  name: "InvoiceOrders",
  data() {
    return {
      currentPage: 1,
      totalPages: 3,
      itemsPerPage: 10,
      ORDER_STATUSES_COLORS,
    }
  },
  methods: {
    ...mapActions_Invoice(["fetchOrders"]),
    goToPage(page) {
      if (
        page >= 1 &&
        page <= this.getTotalPages(this.ordersResponse?.to, this.ordersResponse?.per_page)
      ) {
        this.currentPage = page
        this.fetchOrders(this.currentPage) // Выполняем запрос на сервер при смене страницы
      }
    },
    getTotalPages(total, perPage) {
      if (total <= perPage) {
        return 1
      }
      return Math.floor(total / perPage)
    },
    invoiceIds(order) {
      const ids = order?.invoices?.map((invoice) => invoice.id);
      return `№ ${ids?.slice(0, 2)?.join(', №')}${ids?.length >= 3 ? '...' : ''}`;
    },
    getInvoiceSum(order) {
      return order?.invoices?.reduce((acc, curr) => {
        return acc += Number(curr.invoice_sum);
      }, 0);
    },
    getPaidSum(order) {
      return order?.invoices?.reduce((acc, curr) => {
        if (curr.status === 'paid') return acc += Number(curr.invoice_sum);
        return acc;
      }, 0);
    },
    getOrderBalance(order) {
      const balance = this.getPaidSum(order) - this.getInvoiceSum(order);
      return `${balance < 0 ? '-' : ''} $${Math.abs(balance)}`;
    }
  },
  computed: {
    ...mapState_Invoice(["ordersResponse"]),
    balance() {
      const balance = this.ordersResponse?.data?.reduce((acc, curr) => {
        return acc += (this.getPaidSum(curr) - this.getInvoiceSum(curr))
      }, 0);
      return `${balance < 0 ? '-' : ''} $${Math.abs(balance)}`;
    }
  },
  mounted() {
    this.fetchOrders(this.currentPage)
  }
}
</script>

<style lang="scss" scoped>
.invoice {
  &__content {
    background-color: #ffffff;
    border-radius: 1px;
    padding: 27px 27px 0;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &__total {
    padding: 27px 0;
    width: 276px;
    background-color: #ffffff;
    border-radius: 1px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    float: right;
    margin-top: 6px;

    span {
      font-family: 'Alegreya Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #666666;
    }

    .total {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      color: #525350;
    }
  }
}

#customers {
  width: 100%;
}

#customers td {
  padding: 8px;
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
}

#customers th {
  padding: 10px;
  text-align: start;
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
}

#customers .plus,
#customers .minus {
  display: block;
}

#customers .repeat,
#customers .show {
  color: #423f3d;
}

#customers .circle {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #cfebcc;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 9px;
}

#customers tr:not(:first-child):hover {
  background-color: #fbfbfb;
}

#customers tr:not(:first-child):not(:last-child) {
  height: 66px;
  border-bottom: 1px solid #f5f5f5;
}

#customers tr:first-child {
  height: 40px;
  background-color: #fbfbfb;
}

#customers tr:first-child th,
#customers tr td:first-child {
  color: #423f3d;
}

#customers tr td {
  color: #666666;
}

.status-active {
  color: #9cc198;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.status-active::before {
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background-color: #9cc198;
  display: inline-block;
  margin-right: 6px;
}

.status-pending {
  color: #ecad62;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.status-pending::before {
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background-color: #ecad62;
  display: inline-block;
  margin-right: 6px;
}

table {
  border-spacing: 0px;
}
</style>
